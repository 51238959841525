var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "examStatistics" },
    [
      _c("van-divider", [_vm._v("题目")]),
      _vm.showLoading
        ? _c("van-loading", {
            staticStyle: { "text-align": "center" },
            attrs: { size: "18", color: "#1989fa" }
          })
        : [
            _c("h4", [_vm._v(_vm._s(_vm.data.arrangeName))]),
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("题目数量")]),
                _c("p", [
                  _c("em", [_vm._v(_vm._s(_vm.data.totalQuestionQty))]),
                  _vm._v("道 ")
                ])
              ]),
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("考试用时")]),
                _vm.data.usedTime
                  ? _c("p", [
                      _c("em", [
                        _vm._v(
                          _vm._s(
                            (
                              _vm.data.usedTime && _vm.data.usedTime / 60
                            ).toFixed(1)
                          ) + " 分钟"
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("卷面总分")]),
                _c("p", [
                  _c("em", [
                    _vm._v(
                      _vm._s(_vm.data.maxScore && _vm.data.maxScore.toFixed(1))
                    )
                  ]),
                  _vm._v("分 ")
                ])
              ]),
              _c("li", [
                _c("i"),
                _c("span", [_vm._v("考试次数")]),
                _c("p", [_c("em", [_vm._v(_vm._s(_vm.useRate))])])
              ])
            ]),
            _c("h5", [_vm._v("总体分析：")]),
            _c("ul", { staticClass: "allAnalyse" }, [
              _c("li", [
                _c("span", [_vm._v("得分：")]),
                _c("em", [
                  _vm._v(
                    _vm._s(_vm.data.score && _vm.data.score.toFixed(1)) + "分"
                  )
                ])
              ]),
              _c("li", [
                _c("span", [_vm._v("排名：")]),
                _c("em", [
                  _vm._v(
                    _vm._s(_vm.data.scoreRank) +
                      "/" +
                      _vm._s(_vm.data.totalUserExam)
                  )
                ])
              ]),
              _c("li", [
                _c("span", [_vm._v("用时：")]),
                _c("em", [
                  _vm._v(
                    _vm._s(
                      _vm.data.usedTime && (_vm.data.usedTime / 60).toFixed(1)
                    ) + " 分钟"
                  )
                ])
              ]),
              _c("li", [
                _c("span", [_vm._v("排名：")]),
                _c("em", [
                  _vm._v(
                    _vm._s(_vm.data.usedTimeRank) +
                      "/" +
                      _vm._s(_vm.data.totalUserExam)
                  )
                ])
              ]),
              _c("li", [
                _c("span", [_vm._v("正确率：")]),
                _c("em", [_vm._v(_vm._s(_vm.data.correctRate) + "%")])
              ]),
              _c("li", [
                _c("span", [_vm._v("排名：")]),
                _c("em", [
                  _vm._v(
                    _vm._s(_vm.data.correctRank) +
                      "/" +
                      _vm._s(_vm.data.totalUserExam) +
                      " "
                  )
                ])
              ])
            ]),
            _c("h5", [_vm._v("答题正确率分析：")]),
            _c("ul", { staticClass: "allAnalyse" }, [
              _vm.data.totalSingleChoice +
                _vm.data.totalMultiChoice +
                _vm.data.totalJudge +
                _vm.data.totalFillin +
                _vm.data.totalQa <=
              0
                ? _c("li", [_vm._v(" 暂无试题统计信息 ")])
                : _vm._e(),
              _vm.data.totalSingleChoice
                ? _c("li", [
                    _c("span", [_vm._v("单选题：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.data.correctSingleChoice) +
                          "题/" +
                          _vm._s(_vm.data.totalSingleChoice) +
                          "题"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalSingleChoice
                ? _c("li", [
                    _c("span", [_vm._v("排名：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.data.correctSingleChoiceRank > 0
                            ? _vm.data.correctSingleChoiceRank
                            : "～"
                        ) +
                          "/" +
                          _vm._s(_vm.data.totalUserExam)
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalMultiChoice
                ? _c("li", [
                    _c("span", [_vm._v("多选题：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.data.correctMultiChoice) +
                          "题/" +
                          _vm._s(_vm.data.totalMultiChoice) +
                          "题"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalMultiChoice
                ? _c("li", [
                    _c("span", [_vm._v("排名：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.data.correctMultiChoiceRank > 0
                            ? _vm.data.correctMultiChoiceRank
                            : "～"
                        ) +
                          "/" +
                          _vm._s(_vm.data.totalUserExam)
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalJudge
                ? _c("li", [
                    _c("span", [_vm._v("判断题：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.data.correctJudge) +
                          "题/" +
                          _vm._s(_vm.data.totalJudge) +
                          "题"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalJudge
                ? _c("li", [
                    _c("span", [_vm._v("排名：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.data.correctJudgeRank > 0
                            ? _vm.data.correctJudgeRank
                            : "～"
                        ) +
                          "/" +
                          _vm._s(_vm.data.totalUserExam)
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalFillin && _vm.data.isAutoMarkFillIn
                ? _c("li", [
                    _c("span", [_vm._v("填空题：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.data.correctFillin) +
                          "题/" +
                          _vm._s(_vm.data.totalFillin) +
                          "题"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalFillin && !_vm.data.isAutoMarkFillIn
                ? _c("li", [
                    _c("span", [_vm._v("填空题：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(_vm.data.fillinScore.toFixed(1)) +
                          "分/" +
                          _vm._s(_vm.data.totalFillinScore.toFixed(1)) +
                          "分"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalFillin
                ? _c("li", [
                    _c("span", [_vm._v("排名：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.data.correctFillinRank > 0
                            ? _vm.data.correctFillinRank
                            : "～"
                        ) +
                          "/" +
                          _vm._s(_vm.data.totalUserExam)
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalQa
                ? _c("li", [
                    _c("span", [_vm._v("问答题：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.data.qaScore && _vm.data.qaScore.toFixed(1)
                        ) +
                          "分/" +
                          _vm._s(
                            _vm.data.qaTotalScore &&
                              _vm.data.qaTotalScore.toFixed(1)
                          ) +
                          "分"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.data.totalQa
                ? _c("li", [
                    _c("span", [_vm._v("排名：")]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.data.qaScoreRank > 0 ? _vm.data.qaScoreRank : "～"
                        ) +
                          "/" +
                          _vm._s(_vm.data.totalUserExam)
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _c("h5", [_vm._v("查看答卷：")]),
            _c("p", [
              _vm.data.viewStartTime && _vm.data.isAllowViewResult === 1
                ? _c("span", [_vm._v(_vm._s(_vm.data.viewStartTime))])
                : _vm._e(),
              _vm.data.viewStartTime &&
              _vm.data.viewEndTime &&
              _vm.data.isAllowViewResult === 1
                ? _c("span", [_vm._v(" 至 ")])
                : _vm._e(),
              _vm.data.viewEndTime && _vm.data.isAllowViewResult === 1
                ? _c("span", [_vm._v(_vm._s(_vm.data.viewEndTime))])
                : _vm._e(),
              !_vm.data.viewStartTime &&
              _vm.data.viewEndTime &&
              _vm.data.isAllowViewResult === 1
                ? _c("span", [_vm._v(" 前")])
                : _vm._e(),
              !_vm.data.viewEndTime &&
              _vm.data.viewStartTime &&
              _vm.data.isAllowViewResult === 1
                ? _c("span", [_vm._v(" 后")])
                : _vm._e(),
              _c("span", [
                _vm._v(
                  _vm._s(
                    !_vm.data.viewEndTime &&
                      !_vm.data.viewStartTime &&
                      _vm.data.isAllowViewResult == 0
                      ? "不允许查看答题详情"
                      : ""
                  )
                )
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    !_vm.data.viewEndTime &&
                      !_vm.data.viewStartTime &&
                      _vm.data.isAllowViewResult == 1
                      ? "不限时"
                      : ""
                  )
                )
              ])
            ]),
            !_vm.data.isAllowViewResult
              ? _c("button", { attrs: { disabled: "disabled" } }, [
                  _vm._v("不允许查看答案详情")
                ])
              : _vm._e(),
            _vm.data.isAllowViewResult
              ? _c(
                  "button",
                  {
                    attrs: {
                      disabled:
                        _vm.data.isShowScore && !!_vm.data.viewTimeExpired
                    },
                    on: { click: _vm.goAnswerList }
                  },
                  [_vm._v(" 答案详情 ")]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }