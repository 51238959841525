<template>
    <div class="examStatistics">
        <van-divider>题目</van-divider>
        <van-loading size="18" color="#1989fa" v-if="showLoading" style="text-align: center" />
        <template v-else>
            <h4>{{ data.arrangeName }}</h4>
            <ul class="list">
                <li>
                    <i></i>
                    <span>题目数量</span>
                    <p>
                        <em>{{ data.totalQuestionQty }}</em
                        >道
                    </p>
                </li>
                <li>
                    <i></i>
                    <span>考试用时</span>
                    <p v-if="data.usedTime">
                        <em>{{ (data.usedTime && data.usedTime / 60).toFixed(1) }} 分钟</em>
                    </p>
                </li>
                <li>
                    <i></i>
                    <span>卷面总分</span>
                    <p>
                        <em>{{ data.maxScore && data.maxScore.toFixed(1) }}</em
                        >分
                    </p>
                </li>
                <li>
                    <i></i>
                    <span>考试次数</span>
                    <p>
                        <em>{{ useRate }}</em>
                    </p>
                </li>
            </ul>
            <h5>总体分析：</h5>
            <ul class="allAnalyse">
                <li>
                    <span>得分：</span><em>{{ data.score && data.score.toFixed(1) }}分</em>
                </li>
                <li>
                    <span>排名：</span><em>{{ data.scoreRank }}/{{ data.totalUserExam }}</em>
                </li>
                <li>
                    <span>用时：</span><em>{{ data.usedTime && (data.usedTime / 60).toFixed(1) }} 分钟</em>
                </li>
                <li>
                    <span>排名：</span><em>{{ data.usedTimeRank }}/{{ data.totalUserExam }}</em>
                </li>
                <li>
                    <span>正确率：</span><em>{{ data.correctRate }}%</em>
                </li>
                <li>
                    <span>排名：</span><em>{{ data.correctRank }}/{{ data.totalUserExam }} </em>
                </li>
            </ul>
            <h5>答题正确率分析：</h5>
            <ul class="allAnalyse">
                <li v-if="data.totalSingleChoice + data.totalMultiChoice + data.totalJudge + data.totalFillin + data.totalQa <= 0">
                    暂无试题统计信息
                </li>
                <li v-if="data.totalSingleChoice">
                    <span>单选题：</span><em>{{ data.correctSingleChoice }}题/{{ data.totalSingleChoice }}题</em>
                </li>
                <li v-if="data.totalSingleChoice">
                    <span>排名：</span><em>{{ data.correctSingleChoiceRank > 0 ? data.correctSingleChoiceRank : '～' }}/{{ data.totalUserExam }}</em>
                </li>
                <li v-if="data.totalMultiChoice">
                    <span>多选题：</span><em>{{ data.correctMultiChoice }}题/{{ data.totalMultiChoice }}题</em>
                </li>
                <li v-if="data.totalMultiChoice">
                    <span>排名：</span><em>{{ data.correctMultiChoiceRank > 0 ? data.correctMultiChoiceRank : '～' }}/{{ data.totalUserExam }}</em>
                </li>
                <li v-if="data.totalJudge">
                    <span>判断题：</span><em>{{ data.correctJudge }}题/{{ data.totalJudge }}题</em>
                </li>
                <li v-if="data.totalJudge">
                    <span>排名：</span><em>{{ data.correctJudgeRank > 0 ? data.correctJudgeRank : '～' }}/{{ data.totalUserExam }}</em>
                </li>
                <li v-if="data.totalFillin && data.isAutoMarkFillIn">
                    <span>填空题：</span><em>{{ data.correctFillin }}题/{{ data.totalFillin }}题</em>
                </li>
                <li v-if="data.totalFillin && !data.isAutoMarkFillIn">
                    <span>填空题：</span><em>{{ data.fillinScore.toFixed(1) }}分/{{ data.totalFillinScore.toFixed(1) }}分</em>
                </li>
                <li v-if="data.totalFillin">
                    <span>排名：</span><em>{{ data.correctFillinRank > 0 ? data.correctFillinRank : '～' }}/{{ data.totalUserExam }}</em>
                </li>
                <li v-if="data.totalQa">
                    <span>问答题：</span><em>{{ data.qaScore && data.qaScore.toFixed(1) }}分/{{ data.qaTotalScore && data.qaTotalScore.toFixed(1) }}分</em>
                </li>
                <li v-if="data.totalQa">
                    <span>排名：</span><em>{{ data.qaScoreRank > 0 ? data.qaScoreRank : '～' }}/{{ data.totalUserExam }}</em>
                </li>
            </ul>
            <h5>查看答卷：</h5>
            <p>
                <span v-if="data.viewStartTime && data.isAllowViewResult === 1">{{ data.viewStartTime }}</span>
                <span v-if="data.viewStartTime && data.viewEndTime && data.isAllowViewResult === 1">
                    至
                </span>
                <span v-if="data.viewEndTime && data.isAllowViewResult === 1">{{ data.viewEndTime }}</span>
                <span v-if="!data.viewStartTime && data.viewEndTime && data.isAllowViewResult === 1"> 前</span>
                <span v-if="!data.viewEndTime && data.viewStartTime && data.isAllowViewResult === 1"> 后</span>
                <span>{{ !data.viewEndTime && !data.viewStartTime && data.isAllowViewResult == 0 ? '不允许查看答题详情' : '' }}</span>
                <span>{{ !data.viewEndTime && !data.viewStartTime && data.isAllowViewResult == 1 ? '不限时' : '' }}</span>
            </p>
            <button disabled="disabled" v-if="!data.isAllowViewResult">不允许查看答案详情</button>
            <button :disabled="data.isShowScore && !!data.viewTimeExpired" v-if="data.isAllowViewResult" @click="goAnswerList">
                答案详情
            </button>
        </template>
    </div>
</template>

<script>
import { Divider, Loading } from 'vant'
import { examStatistics } from '@/api/exam.api.js'
export default {
    name: 'examStatistics',
    components: {
        'van-divider': Divider,
        'van-loading': Loading
    },
    data() {
        return {
            showLoading: false,
            userExamId: null,
            arrangeId: null,
            userExamMapId: null,
            data: {},
            LBtnInfo: null,
            disabledBtn: false
        }
    },
    computed: {
        // 已考次数/设定重复次数
        useRate() {
            if (!this.data.examTimes === 1) {
                // 不允许重复考
                let usedTimes = 0
                if (this.data.lastStatus !== 'NotStarted') {
                    usedTimes = 1
                }
                return `${usedTimes}/1次`
            } else if (this.data.examTimes === 0) {
                // 不限次数
                this.times = ''
                return '不限'
            } else {
                return `${this.data.usedExamTimes}/${this.data.examTimes}次`
            }
        }
    },
    created() {
        this.userExamId = this.$route.query.userExamId
        this.arrangeId = this.$route.query.arrangeId
        this.userExamMapId = this.$route.query.userExamMapId
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.showLoading = true
            examStatistics(this.userExamId, this.arrangeId)
                .then(res => {
                    this.data = res
                    this.showLoading = false
                })
                .catch(err => {
                    console.log('err---', err)
                })
        },
        goAnswerList() {
            this.$router.push({
                path: '/exam/answerList',
                query: {
                    userExamId: this.userExamId,
                    arrangeId: this.arrangeId,
                    userExamMapId: this.userExamMapId
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.examStatistics {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /deep/.van-divider {
        font-size: 24px;
        color: @color3;
        border-color: #d8d8d8;
    }
    h4 {
        font-size: 32px;
        color: @color3;
        text-align: center;
        margin-bottom: 30px;
    }
    .list {
        display: flex;
        margin-bottom: 30px;
        li {
            flex: 1;
            text-align: center;
            i {
                width: 86px;
                height: 86px;
                border-radius: 50%;
                display: inline-block;
                margin: 0 auto;
            }
            &:nth-child(1) {
                i {
                    background: url('../../assets/icon1.png');
                    background-size: 100%;
                }
            }
            &:nth-child(2) {
                i {
                    background: url('../../assets/icon2.png');
                    background-size: 100%;
                }
            }
            &:nth-child(3) {
                i {
                    background: url('../../assets/icon3.png');
                    background-size: 100%;
                }
            }
            &:nth-child(4) {
                i {
                    background: url('../../assets/icon4.png');
                    background-size: 100%;
                }
            }
            span {
                font-size: 22px;
                color: #999999;
                display: block;
            }
            p {
                font-size: 28px;
                color: #151515;
                margin-top: 20px;
                em {
                    font-size: 28px;
                    color: @blueTheme;
                }
            }
        }
    }
    h5 {
        font-size: 28px;
        color: @color3;
        text-align: left;
        margin: 20px 30px;
    }
    .allAnalyse {
        display: flex;
        flex-wrap: wrap;
        margin: 0 30px;
        align-items: center;
        margin-bottom: 30px;
        li {
            width: 50%;
            font-size: 28px;
            color: @color6;
            margin: 15px 0;
            span {
                font-size: 28px;
                color: @color6;
            }
            em {
                font-size: 28px;
                color: @color3;
            }
        }
    }
    p {
        font-size: 28px;
        color: @color6;
        margin: 20px 30px;
    }
    button {
        border: none;
        display: block;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 0 40px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 28px;
        background: @blueTheme;
        outline: none;
        border-radius: 30px;
        &:disabled {
            background: #98c8ec;
        }
    }
}
</style>
